*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  background: #ffe474;
  box-sizing: border-box;
  overflow: hidden !important;
  touch-action: manipulation;
  margin: 0;
}

.body-setting {
  background: #828282;
}

.body-blind {
  background: #1a1a1a;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.icon-tabs img {
  width: 32px;
  height: 32px;
}
